import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const AcceleratorPage = () => (
  <div>
      <SEO 
        title="Website Accelerator Tools" 
        description="Supercharge your sites without making any back–end code tweaks. We’ve included a couple of Website Accelerator Tools to your Web Control Panel so you’re able to effectively 
        with ease improve the overall effectiveness of your sites."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title">Website <span className="block text-indigo-600 xl:inline">Accelerator</span> Tools <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">Supercharge your sites without making any back–end code tweaks</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/web-accelerators-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> We’ve included a couple of Website Accelerator Tools to your Web Control Panel so you’re able to effectively 
                 with ease improve the overall effectiveness of your sites. <br/><br/>
                 <span role="img" aria-label="emoji">✨</span> You won’t have to transform anything at all 
                 inside the program code or make specific configurations that demand technical comprehension from you. 
                 Inside the Web Control Panel, only choose the application you wish to implement – Node.js, Memcached 
                 and Varnish and create an instance for it. It’s all finished with a mouse click. By accelerating 
                 your sites, you’ll not just prevent your visitors from having to wait but can even help your web 
                 site climb high in search results.
                </p>
                <p className="section-after-title">
                <span role="img" aria-label="emoji">🌈</span> You will find the Website Accelerator Tools in the Advanced Tools section of the Web Control Panel.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/memcached.webp" className="w-60" alt="Memcached" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Memcached </p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    RAM–caching as a substitute for data base requests<br/><br/>
                                    If you’ve got a busy database–driven site or app, it could have problems running quick for the customers because of 
                                    the different requests sent to the data base. To let you solve the page running difficulty, we have incorporated 
                                    the Memcached tool inside the Web Control Panel.
                                    <br/><br/>
                                    Memcached is a powerful memory–based object store, which caches information and objects in the server’s memory in 
                                    order to avoid the data base from getting queried each time a visitor opens up a particular web page. Using this 
                                    method, your web site pages are going to load a lot faster for website visitors and definately will boost the 
                                    possibility for them to return.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Varnish</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    RAM–storing as a substitute for HTTP calls<br/><br/>
                                    Should you have content–heavy active web sites with many different photos as well as video clips, you will most 
                                    certainly have to be sure that your pages run really fast for the visitors. A very good tool you could use is 
                                    the Varnish HTTP accelerator that will help you quicken your websites without requiring that you have any 
                                    special computing skills.
                                    <br/><br/>
                                    Varnish caches all queries towards the server in the server RAM and provides the pages speedily to the customer 
                                    by making unnecessary new requests to the web server. That way, the pages on your website will be loaded 300 – 
                                    1000x times faster to your visitors. Additionally, you can pick if the inbound calls will be managed by 
                                    Varnish, or by the web server, and so forth.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/varnish.webp" className="w-60" alt="Varnish" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/nodejs.webp" className="w-60" alt="Node.js" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Node.js</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    An effective way to create flexible apps<br/><br/>
                                    If you want to establish an application, you ought to have all the tools you may need readily available at once, 
                                    without needing to browse, arrange and have them set up. Sheephostingbay’s Web Control Panel will save you both 
                                    cash and time, by supplying you with the tools you will need right at your fingertips.
                                    <br/><br/>
                                    The Node.js application will allow developers, regardless of whether they’re experts or otherwise, to develop 
                                    flexible network programs and websites. It’s based on the Google V8 JavaScript engine along with the libUV. 
                                    Node.js utilizes an event–driven, non–blocking I/O model that makes it compact and also reliable, suitable 
                                    for data–intensive real–time apps running over distributed devices.
                                    </div>
                                </div>
                            </div>
                        </div>       
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default AcceleratorPage
